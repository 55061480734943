import React from 'react'
import Layout from "../components/layout";

const Thanks = () => (
  <Layout>
      <section style={{textAlign: 'center', marginTop: '1rem'}}>
        <h1>Thank you!</h1>
        <p>We will be in touch with you shortly.</p>
        <p>In the meantime. <a href="https://www.facebook.com/kckbeautybarberacademy/" target="_blank">Join our Facebook Group!</a></p>
      </section>
  </Layout>
)

export default Thanks